


































import ScreeningSelect from "@/components/hae/ScreeningSelect.vue";
import CommonButton from "@/components/hae/CommonButton.vue";
import QuoteOrigin from "@/components/hae/QuoteOrigin.vue";
import ScreeningHeadline from "@/components/hae/ScreeningHeadline.vue";
import rootStore from "@/store";
import { HaeState } from "@/types/hae";
import { questions } from "../../data/hae";
const state = rootStore.state as { hae: HaeState };
const isPresent = (el: number | undefined | null | number[]) =>
  el !== undefined && el !== null && (typeof el === "number" || el.length > 0);
export default {
  name: "HaeThirdScreening",
  head: {
    title: {
      inner: "かんたんHAEセルフチェック"
    }
  },
  components: {
    ScreeningSelect,
    CommonButton,
    ScreeningHeadline,
    QuoteOrigin
  },
  data: function() {
    return {
      questions: questions().slice(6, 18)
    };
  },
  computed: {
    isAnswerComplete(): boolean {
      return state.hae.screeningAnswers.slice(6, 18).every(el => isPresent(el));
    }
  }
};
